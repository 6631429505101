import * as React from 'react';
import {Header} from '../components/header/Header';
import './index.scss';
import {CSSTransition} from 'react-transition-group';
import Logo from './../images/svg/logo.svg';
import {Footer} from '../components/footer/Footer';
import Container from 'react-bootstrap/esm/Container';
import {QAllImages} from '../components/QAllImages/QAllImages';
import {MainSlider} from '../components/main-slider/MainSlider';
import {AllImages} from '../components/QAllImages/__generated__/AllImages';
import {GalleryTiles} from '../components/gallery-tiles/GalleryTiles';
import {PageHead} from '../components/page-head/PageHead';
import {AboutMe} from '../components/about-me/AboutMe';
import { Link } from 'gatsby';

const IndexPage = () => {
  return (
    <>
      <PageHead/>
      <Header />
      <div className="main-slider-container">
        <QAllImages render={(allImages: AllImages) => <MainSlider allImages={allImages}/>}/>
        <section className="labels">

          <CSSTransition in={true} appear={true} timeout={1000} classNames="logo">
            <Logo className="logo"/>
          </CSSTransition>

          <CSSTransition in={true} appear={true} timeout={1000} classNames="galery-btn">
            <Link className="galery-btn btn" to="#galleries">
              To Galleries
            </Link>
          </CSSTransition>
        </section>
      </div>
      <Container>
        <AboutMe/>
        <QAllImages render={(allImages: AllImages) =>
          <div id="galleries">
            <GalleryTiles allImages={allImages} name="Galleries"/>
          </div>}/>
      </Container>
      <Footer />
    </>
  );
};

export default IndexPage;
