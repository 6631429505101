import {StaticImage} from 'gatsby-plugin-image';
import * as React from 'react';

export const AboutMe: React.VoidFunctionComponent = () => <>
  <h2 className="text-center">About Me</h2>
  <div className="row d-flex align-items-start justify-content-center">
    <div className="col-lg-6">
      <StaticImage className="roundedImage" src="../../images/myself.jpeg" alt="Alexey Ulashchick" />
    </div>

    <div className="col-lg-6">
      <p className="lead">
        I&apos;m Alexey Ulashchick, Landscape & Travel Hobbyist Photographer. My family and I are passionate about traveling.
        I love taking photos of the most stunning places around the world.
      </p>
      <p>
        Besides photography, I&apos;m a Senior Software Engineer from Silicon Valley currently working for Apple. Originally
        came from Belarus, I&apos;m a husband and proud father beautiful girl.
      </p>
    </div>
  </div>
</>;
