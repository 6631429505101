import * as React from 'react';
import './Arrow.scss';

export type ArrowProps = {
  position: 'left'|'right';
  className?: string;
  onClick: () => void | {};
};

export const Arrow: React.FC<ArrowProps> = ({position, className, onClick}: ArrowProps) => {
  return <div className={`pointer ${className} ${position}`} onClick={() => onClick()}></div>;
};
