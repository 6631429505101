import {GatsbyImage} from 'gatsby-plugin-image';
import './GalleryTiles.scss';
import * as React from 'react';
import {getImageData} from '../../utils/data';
import {ImageProps} from '../QAllImages/QAllImages';
import {QAllGalleries} from '../QAllGalleries/QAllGalleries';
import {AllGalleries_allGalleriesJson_nodes} from '../QAllGalleries/__generated__/AllGalleries';
import { Link } from 'gatsby';
import { slugify } from '../../utils/slug';

export type GalleryTilesProps = {
  name: string;
}

export const GalleryTiles: React.FC<GalleryTilesProps & ImageProps> = ({allImages, name}: GalleryTilesProps & ImageProps) => {
  return (
    <>
      <h2 className="text-center">{name}</h2>
      <div className="row">
        <QAllGalleries render={(allGalleries: AllGalleries_allGalleriesJson_nodes[]) =>
          <>
            {allGalleries.map((gallery:AllGalleries_allGalleriesJson_nodes, index:number) => (
              <div key={index} className="tile col-md-6 col-lg-4">
                <Link to={`/${slugify(gallery.galleryName ?? '')}/`}>
                  <GatsbyImage
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    image={getImageData(gallery.imageName ?? '', allImages)}
                    alt={gallery.galleryName ?? ''}
                  />
                </Link>
                <h4 className="galleryName text-center darkBg">{gallery.galleryName}</h4>
              </div>
            ))}
          </>
        }/>
      </div>
    </>
  );
};
