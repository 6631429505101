import * as React from 'react';
import {FunctionComponent} from 'react';
import {baseClassName} from '../../utils/classNames';
import {Range} from 'immutable';
import './SliderIndicator.scss';

export type SliderIndicatorProps = {
  active: number;
  total: number;
  onClick?: (index: number) => void;
};

export const SliderIndicator: FunctionComponent<SliderIndicatorProps> = ({active, total, onClick}: SliderIndicatorProps) => {
  const indicatorClass = baseClassName('bubble');
  const clickHandler = onClick ??= () => {};

  return (
    <div className="bubble-container">
      {Range(0, total).map((index) => (
        <div key={index} className={indicatorClass({active: index == active})} onClick={() => clickHandler(index)}></div>
      ))}
    </div>
  );
};
