import * as React from 'react';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {useCallback, useEffect, useState} from 'react';
import './MainSlider.scss';
import {Range} from 'immutable';
import {Arrow} from '../arrow/Arrow';
import {baseClassName} from '../../utils/classNames';
import {SliderIndicator} from '../slider-indicator/SliderIndicator';
import {getImageData} from '../../utils/data';
import {ImageProps} from '../QAllImages/QAllImages';
import {MainSliderData} from './__generated__/MainSliderData';
import {graphql, useStaticQuery} from 'gatsby';

function getMainSliderImages(mainSliderData: MainSliderData): (string | null)[] {
  return mainSliderData.allDataJson?.nodes?.[0]?.images ?? [];
}

/** Memoized Gatsby Image prevents component re-rendering and flicking during anumations. */
const MemooizedGatsbyImage = React.memo(({index, allImages}: {index: number} & ImageProps) => {
  const mainSliderImages = getMainSliderImages(useStaticQuery(query));
  const imageData = getImageData(mainSliderImages[index], allImages);

  return <GatsbyImage style={{height: '100%', width: '100%'}} image={imageData} alt="Main Slider image" />;
}, (a,b) => a.index !== b.index);

export const MainSlider: React.FC<ImageProps> = ({allImages}: ImageProps) => {
  const total: number = getMainSliderImages(useStaticQuery(query)).length;
  const imageClass = baseClassName('image');

  const [activeIndex, setCount] = useState(0);
  const increment = useCallback(() => setCount((count) => (count + 1) % total), []);
  const decrement = useCallback(() => setCount((count) => (count === 0 ? total - 1 : count - 1)), []);

  useEffect(() => {
    const interval = setInterval(increment, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-container">
      {Range(0, total).map((index) => (
        <div key={`${index}`} className={imageClass({active: index == activeIndex})}>
          <MemooizedGatsbyImage index={index} allImages={allImages}/>
        </div>
      ))}

      <div className="navigation-container">
        <Arrow position="left" onClick={decrement}></Arrow>
        <Arrow position="right" onClick={increment}></Arrow>
      </div>

      <div className="indicator-container">
        <SliderIndicator total={total} active={activeIndex} onClick={(index) => setCount(index)}></SliderIndicator>
      </div>
    </div>
  );
};

const query = graphql`
  query MainSliderData {
    allDataJson {
      nodes {
        images
      }
    }
  }
`;
